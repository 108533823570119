$light-black: #343647;
$shadow: #1d1e25;
$dark-black: #252735;
$paragraph: #b2b2b2;
$Yellow: #ffaf29;
$violet: #a291fb;
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

@mixin mobile {
  @media (max-width: 992px) {
    @content;
  }
}

::selection {
  background-color: #a291fb;
  color: $dark-black;
}
a {
  text-decoration: none;
}
a:hover {
  color: white;
}
:root {
  --mouseX: 50%;
  --mouseY: 50%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
}
.d-block-lg {
  display: block;
  @include mobile() {
    display: none;
  }
}
.d-block-sm {
  display: none;
  @include mobile() {
    display: block;
  }
}
.d-flex-sm {
  display: none;
  @include mobile() {
    display: flex;
  }
}
.navbar-toggler {
  .navbar-toggler-icon {
    background-image: url("../img/menu.svg");
    background-repeat: no-repeat;
  }
}

.show {
  a {
    margin: 30px 30% !important;
    text-align: center;
  }
}

body {
  background-color: $dark-black;
  font-family: "Open Sans";
  color: white;
  a {
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
  p {
    color: $paragraph;
  }
  .row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }
  .modal-bg {
    overflow: hidden;
  }
}
.nav {
  align-items: center;
  .nav-item {
    margin: 0 20px;
    a {
      padding: 0px 0px 5px 0;
      &:hover {
        transition: all 500ms;
        color: $violet;
      }
    }
  }
}

.light {
  background-color: $light-black;
}
.bg-light {
  background-color: rgb(41, 43, 58) !important;
  position: fixed;
  z-index: 500;
  width: 100%;
  box-shadow: 0 2px 10px #0000003b;
  a {
    margin: 0;
    display: block;
    margin-right: 40px;
    &:hover {
      color: $violet;
    }
  }
}
.myPhoto {
  position: relative;
  @include mobile() {
    margin-top: 20%;
  }
}
.landing-bg {
  height: auto;
  min-height: 100vh;
  background-image: url("../img/landing.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include mobile() {
    padding-top: 20%;
  }
}
.line {
  box-shadow: 0 2px 15px $dark-black;
  display: block;
  background-color: $violet;
  width: 100px;
  height: 3px;
}
.name {
  h1 {
    color: white;
    font-size: 4em;
    text-shadow: 0 2px 15px $dark-black;
    @include mobile() {
      font-size: 2em;
    }
  }

 
  .d-flex{
    @include mobile() {
      justify-content: space-between;
    }
  }

  p {
    font-size: 22px;
    text-shadow: 0 2px 15px $dark-black;
    color: white !important;

  }
  .linerBtn {
    box-shadow: 0 2px 15px $dark-black;
    display: block;
    border: 3px solid $violet;
    border-radius: 3px;
    color: $violet;
    padding: 10px 10px;
    width: 200px;
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
    transition: all 500ms;
    margin-left: 30px;
    &:hover {
      color: white;
      border: 3px solid white;
    }
  }
  .filledBtn {
    box-shadow: 0 2px 15px $dark-black;
    background-color: $violet;
    display: block;
    border-radius: 3px;
    color: white;
    padding: 10px 10px;
    width: 200px;
    line-height: 34px;
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
    transition: all 500ms;

    &:hover {
      color: $violet;
      background-color: white;
    }
  }
}
.services {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    @extend .line;
    margin-bottom: 30px;
  }
}
.projects {
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.projects div {
  @include mobile() {
    width: 100% !important;
    justify-content: space-around !important;
  }
}

.works {
  height: 100%;
  padding: 50px;
  background-color: $light-black;
  box-shadow: 0 2px 45px $shadow;
  transition: all 500ms;
  margin-bottom: 30px;
  span {
    @extend .line;
  }
  &:hover {
    background-color: #2d2e3b;
  }
}
.ligt {
  background-color: $light-black;
}
.aboutMe {
  height: 100%;
  display: flex;
  align-items: center;
}

.story {
  span {
    @extend .line;
  }
}
.Projects {
  div {
    display: flex;
    padding: 15px 15px 15px 0;
    align-items: center;

    h6 {
      color: white;
    }
    span {
      width: 80px;
      height: 80px;
      margin-left: 20px;
      border: 2px dashed $violet;
      border-radius: 3px 30px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $Yellow;
      font-size: 30px;
    }
  }
}
.skills-bg {
  background-image: url("../img/skillsbg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: var(--mouseX) var(--mouseY);
  transition: all 500ms;

  .progress-box {
    .progress-bar {
      background-color: transparent;
      height: 13px;
      margin-bottom: 35px;
      .progress-bar-rect-wrapper {
        height: 7px !important;
      }
    }
  }
  .inner-rect-bar {
    background-color: $Yellow !important;
  }
}
.progress-bar {
  @include mobile() {
    width: 100% !important;
  }
}

.devLogos {
  display: flex;
  justify-content: center;
  @include mobile() {
    margin-bottom: 80px;
  }
  div {
    position: absolute;
    padding: 30px;
    width: 70%;
    // height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: $dark-black;
    box-shadow: 0 2px 45px $shadow;
    border-radius: 5px;
    @include mobile() {
      width: 100%;
    }
    svg {
      opacity: 0.8;
      margin: 5px;
    }
  }
}

.experience {
  div {
    background-color: $dark-black;
    box-shadow: 0 2px 15px $dark-black;
    padding: 30px;
    margin-bottom: 30px;
    transition: all 500ms;
    span {
      height: 2px;
    }
    p {
      margin: 15px 0 0 0;
    }
  }
}
.shape {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @include mobile() {
    display: none;
  }

  &:hover {
    div {
      opacity: 0.7;
    }
  }
  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $Yellow;
    transition: all 500ms;
  }
  span {
    display: block;
    width: 2px;
    height: 20%;
    background-color: $Yellow;
  }
}
.tabs {
  margin: 10px 30px;
  // :first-child {
  //   border-right: 2px solid $paragraph;
  // }
  a {
    width: 50%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: white;
    font-size: 25px;
  }
  .active {
    color: $violet !important;
    border-bottom: 1px dashed $violet;
  }
}

.footer {
  background-image: url("../img/footer-bg.svg");
  background-position: right bottom;
  background-size: cover;
  .social {
    margin-top: 10px;
    width: 50%;
    @include mobile() {
      width: 100%;
      margin-top: 20px;
    }
    svg {
      width: 45px;
      transition: all 500ms;

      &:hover {
        fill: $violet;
      }
    }
  }
}
//projects page
.project-gallery {
  @include mobile() {
    width: 100%;
  }
}
.card {
  padding: 15px;
  height: auto;
  .card-img-top {
    border-radius: 3px;
  }
  .card-body {
    padding: 0;
  }

  &:hover {
    .card-img-top {
      cursor: pointer;
      opacity: 0.8;
      transition: all 1s;
    }
    h4,
    P {
      color: #2a2a2a !important;
      transition: all 1s;
    }

    background-color: #a291fb;
    transition: all 1s;
  }
}
.modal-bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 600;
  background-color: #00000054;
  .modal-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    z-index: 700;
    border-radius: 3px;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #252735;
    box-shadow: 0 3px 40px #000000;
    @include mobile {
      width: 95%;
      height: auto;
      padding: 30px;
    }
    .modal-img {
      width: 90%;
      margin: 0 auto;
    }
    .flex-column {
      margin-top: 30px;
    }
    .link {
      border-bottom: 3px solid $violet;
      color: $violet;
      font-size: 18px;
      padding-bottom: 5px;
      margin-top: 10px;
      transition: all 500ms;
      &:hover {
        color: white;
        cursor: pointer;
        border-bottom: 3px solid white;
      }
    }
    .close-modal {
      position: absolute;
      top: 20px;
      right: 20px;
      transition: all 500ms;
      &:hover {
        cursor: pointer;
        box-shadow: 0 3px 10px $shadow;
        opacity: 0.8;
        transition: all 500ms;
      }
    }
  }
}
//not found page
.notfound-bg {
  background-image: url("../img/notfound.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  div {
    height: 100vh;
    .notFound-font {
      font-size: 24px;
    }
  }
}
//contact me page
.contactMe {
  height: 100vh;
  display: flex;
  align-items: center;
  @include mobile() {
    height: fit-content;
  }
  .form-box {
    @include mobile() {
      margin-top: 5em;
    }
    .form-items {
      display: flex;
      margin-bottom: 30px;
      border: 1px solid #525469;
      border-radius: 3px;
      padding: 10px;
      align-items: flex-start;
      background-color: $dark-black;
      &:focus-within {
        border: 1px solid $violet;
      }
      img {
        margin-right: 20px;
      }

      input {
        background-color: transparent;
        border: none;
        width: 90%;
        color: white;

        &:focus {
          outline: none;
        }
        &:-webkit-autofill {
          color: #2a2a2a !important;
        }
      }
      textarea {
        background-color: transparent;
        border: none;
        height: 120px;
        color: white;
        width: 100%;
        max-height: 120px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
.filled {
  box-shadow: 0 2px 15px #252735;
  display: block;
  border-radius: 3px;
  color: white;
  padding: 10px 10px;
  width: 200px;
  background-color: $violet;
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
  border: none;
  transition: all 500ms;
  &:hover {
    color: $violet;
    background-color: white;
  }
}
.contactMe-social {
  @include mobile {
    padding: 60px 15px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}
// input:-internal-autofill-selected {
//   background-color: transparent !important;
// }

// menu icon
/*--------------------------------------
 Animation Magic, animations won't occur
 on IE9 and before and Opera Mini
--------------------------------------*/

/*==============================================================================
 The burger icon with it's animation. Uses the checked property of a checkbox
 in order to make the animation happen as well as control the next elements
 I've used classes because of the + css selector, which will only ever animate
 the next label element. Does not work on IE8 or below and opera mini since
 they don't support the :checked selector.
==============================================================================*/
.burger-check {
  display: none;
}

/*--------------------------------------
 Sets the actionable area for the burger
 including a label for usability
--------------------------------------*/
.burger {
  position: relative;
  float: left;
  margin-left: 10px;
  width: 60px;
  height: 40px;
}

/*--------------------------------------
 Creates the look of the burger icon
 using the inner span and pseudo-elements
--------------------------------------*/
.burger span,
.burger span:before,
.burger span:after {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  left: 10px;
  top: 22px;
  width: 40px;
  height: 4px;
  background: $violet;
  border-radius: 0.2em;
}

/* Reset the left and create the pseudo-element */
.burger span:before,
.burger span:after {
  content: "";
  left: 0;
}

/* Top bar position */
.burger span:before {
  top: -8px;
}

/* Bottom bar position */
.burger span:after {
  top: 8px;
}

/* Get rid of more on action (IE9 or higher) */
.burger-check:checked + .burger:after {
  content: "";
}
/* Get rid of the middle bar on action (IE9 or higher) */

.burger-check:checked + .burger span {
  height: 0;
  width: 0;
}

/* Moves the top and bottom bars to the middle on action (IE9 or higher) */
.burger-check:checked + .burger span:before,
.burger-check:checked + .burger span:after {
  top: 6px;
}

/* Rotates the top bar on action with full browser support (IE9 or higher) */
.burger-check:checked + .burger span:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

/* Rotates the bottom bar on action with full browser support (IE9 or higher) */
.burger-check:checked + .burger span:after {
  -webkit-transform: rotate(-225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(-225deg);
}

/*==============================================================================
 Simple reveal (IE9 or higher)
==============================================================================*/
span.boo {
  width: auto;
  margin-top: 0;
  line-height: 60px;
  height: 0;
  overflow: hidden;
}

/* Reveals Boo! (IE9 or higher) */
#main-nav-test:checked ~ .boo {
  height: 50px;
}

/*==============================================================================
 Simple mobile menu opening and closing (IE9 or higher)
==============================================================================*/
//mobile menu
.mobile-menu {
  z-index: 1000;
  padding: 10px;
  position: fixed;
  width: 100%;
  flex-direction: row-reverse;
  background-color: $dark-black;
  box-shadow: 0 2px 10px $shadow;
  .sidenav,
  .closebtn {
    position: relative;
    top: -50px;
    right: 0;
    width: 50px;
    color: white;

    z-index: 5;
    line-height: 1em !important;
    font-size: 40px !important;
  }

  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: $dark-black;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    .closebtn {
      text-align: right !important;
    }
    a,
    .dropdown-btn {
      padding: 6px 30px 6px 16px;
      text-decoration: none;
      font-size: 20px;
      color: white;
      text-align: center !important;

      line-height: 2em;
      display: block;
      border: none;
      background: none;
      width: 100%;
      text-align: right;
      outline: none;
      border-bottom: 1px solid $dark-black;
    }
    @media screen and (max-height: 450px) {
      .sidenav {
        padding-top: 15px;
      }
      .sidenav a {
        font-size: 18px;
      }
    }
    a:active {
      color: #0aa;
    }

    /* Add an active class to the active dropdown button */
    .active {
      color: white;
      background-color: $light-black;
    }

    .dropdown-container {
      display: none;
      background-color: #2a2a2a;
      padding-left: 8px;
    }

    /* Optional: Style the caret down icon */
    .fa-caret-down {
      float: right;
      padding-right: 8px;
    }
  }
}
body {
  cursor: none;
}
