body {
  cursor: none;
}

.custom-cursor,
.cursor-follower {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 10000;
  transform: translate(-50%, -50%);
}

.custom-cursor {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  transition: transform 500ms ease;
}

.cursor-follower {
  width: 40px;
  height: 40px;
  background-color: #7c72ad5e;
  border-radius: 50%;
  transition: width 500ms ease, height 500ms ease, transform 500ms ease-out;
  transition-delay: 0.1s; /* Added delay */
}

.cursor-follower.is-hovering {
  width: 60px;
  height: 60px;
  animation: bounce 500ms infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -60%) scale(1.1);
  }
}

@media (max-width: 768px) {
  body {
    cursor: auto !important;
  }
  .custom-cursor,
  .cursor-follower {
    display: none;
  }
  .first-section {
    height: fit-content;
  }
}

/* -- floating circles ---- */
.first-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.first-section2 {
  position: relative;
  overflow: hidden !important;
}

.circle {
  position: absolute;
  border-radius: 50%;
  width: 10px;  /* Reduced width */
  height: 10px;  /* Reduced height */
  background-color: #17113549;
  pointer-events: none;
  animation: move infinite linear;
}

@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(var(--move-x), var(--move-y));
  }
}

/* Style for the secret button */
#secret-button {
  position: fixed; /* Changed from absolute to fixed */
  top: 15px; /* Adjust based on your layout */
  right: 50px; /* Adjust based on your layout */
  font-size: 24px;
  color: gold;
  cursor: pointer;
  z-index: 3000;
  border-radius: 100%;
  animation: growShrink 15s infinite; /* Added animation */
}

/* Keyframes for grow and shrink animation */
@keyframes growShrink {
  0%, 100% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
}

/* Modal styling */
.hidden {
  display: none;
}

#fun-fact-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

#fun-fact-content {
  background: #252735;
  padding: 20px;
  width: 50%;
  border-radius: 5px;
}

#fun-fact-content p {
  text-align: center;
}

#filledbtn {
  box-shadow: 0 2px 15px #252735;
  background-color: #a291fb;
  display: block;
  border-radius: 3px;
  color: white;
  padding: 10px 10px;
  width: 300px;
  line-height: 34px;
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
  transition: all 500ms;
  margin: 0 auto;
}

#filledbtn:hover {
  color: #a291fb;
  background-color: white;
}

@media only screen and (max-width: 992px) {
  #secret-button {
    right: 90%; /* Adjust based on your layout */
  }
}
